import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import CTA from "./CTA";
import Link from "gatsby-link";
export default function Example() {
  return (
    <div className="relative py-16 bg-white overflow-hidden">
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        <div
          className="relative h-full text-lg max-w-prose mx-auto"
          aria-hidden="true"
        >
          <svg
            className="absolute top-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
            />
          </svg>
          <svg
            className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
            />
          </svg>
          <svg
            className="absolute bottom-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
            />
          </svg>
        </div>g
      </div>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h2>
            <span className="block text-base text-center text-theme font-semibold tracking-wide uppercase">
              Certus
            </span>
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Freelance Bookkeeper Toronto
            </span>
          </h2>
          <p className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
            Certus Bookkeeping is a leading provider of bookkeeper services for
            both individuals and businesses. We are a team of skilled
            professionals that understands the amount of time and effort it
            takes to keep your books in order. We also understand that managing
            your own finances can be a draining task that takes away from your
            core role as a business owner.
          </p>
          <p className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
            This is why it is always our recommendation to have a professional
            handle your bookkeeping responsibilities. The Certus team is a
            vastly experienced group that prides itself on our excellent time
            management and organizational skills. We are here to help those that
            do not have the time to fill the role of a bookkeeper themselves and
            want to ensure that they are getting the highest quality service on
            the market.
          </p>
          <p className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
            Hiring Certus as your provider of financial services is a decision
            you will truly appreciate. We will bring your business a level of
            stability that will create peace of mind for both owners and
            operators. You will be able to rest easy knowing your finances are
            being managed full time by a team of skilled bookkeepers. It is our
            guarantee that we have the knowledge, experience, and ability to
            handle all of your bookkeeping needs.
          </p>
        </div>
        <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
          <h2>Work With An Experienced Team</h2>
          <p>
            Certus is a proud provider of affordable bookkeeping and accounting
            services in Toronto. We have spent years developing a system that is
            both cost-effective and efficient. We work closely with both
            individuals and small to medium-sized businesses and have built up a
            proven track record of helping our clients get their finances in
            order.
          </p>
          <p>
            Our number one objective is to improve our clients day to day
            workflows. We do this by taking stressful and monotonous work off of
            business owners hands and complete it in an organized manner. We
            estimate that our services will save several hours per week of
            accounting work, freeing valuable time that you can invest into
            developing your business.
          </p>
          <p>
            The first step in our process is the consultation. This is a key
            step that allows us to become familiar with your needs and get to
            know you as a client. Our team of financial experts will analyze the
            information provided and create a plan that will take into
            consideration the goals and objectives of your business.
          </p>
        </div>
        <CTA />
        <div className="prose prose-indigo prose-lg text-gray-500 mx-auto">
          <p>
            Certus Bookkeeping is proud to provide the highest standard of
            professional freelance bookkeeper services in Toronto for both individuals
            and businesses. We are a team that has years of experience in
            providing top-quality service to those seeking to fill the role of a
            bookkeeper in their business.
          </p>
          <h2 style={{ marginTop: "0px" }}>Freelance Services</h2>
          <p>
            Certus Bookkeeping also provides premium bookkeeping services for
            individuals. We understand that managing your finances can be
            overwhelming. We pride ourselves on our ability to help our clients
            get their finances in order.
          </p>
          <p>
            We offer expense tracking, receipt digitization & filing and
            financial reporting. These services will ensure that you are
            prepared for tax season and will save you both time and money. As a
            result, you will not have to stress about your finances being
            organized because you have a team of professionals working full time
            to ensure that you are well looked after.
          </p>
          <p>
            The Certus Bookkeeping team offers payroll processing, management of
            QuickBooks Online, preparation of financial statements, and
            assistance with filing tax returns for small businesses. Our
            services will uncover streams of revenue, help you avoid cash flow
            crunches, and get all of your tax season preparation done on time.
          </p>
          <h2 style={{ marginTop: "0px" }}>Why Choose Certus?</h2>
          <p>
            Businesses across the country are choosing Certus as their provider
            of professional bookkeeping. Our clients can rest easy knowing that
            their business is being looked after by a team of skilled
            bookkeepers.
          </p>
          <p>
            If you are interested in our services please visit the{" "}
            <Link
              to="/contact-us"
              style={{
                textDecoration: "none",
                color: "#6B7280",
                "&:hover": {
                  background: "#efefef",
                },
                ":visited": {
                  color: "purple",
                },
              }}
            >
              contact us{" "}
            </Link>
            page on our website to schedule a consultation with one of our
            financial experts. Choosing Certus is a decision that will save you
            time, money and relieve your stress. Book your consultation today to
            get started!
          </p>
        </div>
      </div>
    </div>
  );
}
