/* This example requires Tailwind CSS v2.0+ */

import React from "react";

import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import Intro from "../page-sections/Freelance/Intro";
import Body from "../page-sections/Freelance/Body";
import FAQ from "../page-sections/Freelance/Faq";
import Features from "../components/Features/Features";
import SEO from "../components/SEO/Freelance/SEO"
export default function Example() {
  return (
    <div>
      <Header />
      <SEO />
      <Intro />
      <Features />
      <Body />
      <FAQ />
      <Footer />
    </div>
  );
}
