import React from "react";
import { Helmet } from 'react-helmet'
export default function Example() {
  return (
    <Helmet htmlAttributes={{
      lang: 'en',
    }}>
      <title>Freelance Bookkeeper Toronto | Freelance Bookkeeping - Certus Bookkeeping</title>
      <meta name="description" content="We offer professional freelance bookkeeper services in Toronto. Call us today to get started with your freelance bookkeeping needs." />
      <link rel="canonical" href="https://certusbookkeeping.com/freelance-bookkeeping-toronto" />
    </Helmet>
  );
}
