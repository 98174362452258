/* This example requires Tailwind CSS v2.0+ */
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/outline";
import React from "react";
import { Helmet } from "react-helmet";
const faqs = [
  {
    question: "How much does it cost to run a payroll?",
    answer:
      "Payroll costs vary depending on the provider as well as the payroll frequency. On average, running payroll for your business can cost approx. $17.00 - $20.00 + HST per payroll run with additional fees per employee. For one employee on bi-weekly payroll (most common) this will amount to $34.00 - $40.00 + HST per month.",
  },
  {
    question: "How do I do the bookkeeping for a small company?",
    answer:
      "The best way to ensure correct bookkeeping for your small company is to hire a high end bookkeeping provider. A provider such as Certus Bookkeeping will ensure that an efficient infrastructure is put in place and they will manage this infrastructure on the back-end. This will not only reduce your internal workload and allow you to focus on growing your business but it will also allow you to effectively scale as you continue to bring in more and more customers.",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Example() {
  return (
    <div className="bg-gray-50">
      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [{
              "@type": "Question",
            "name": "How much does it cost to run a payroll?",
            "acceptedAnswer": {
              "@type": "Answer",
            "text": "<p>Payroll costs vary depending on the provider as well as the payroll frequency. On average, running payroll for your business can cost approx. $17.00 - $20.00 + HST per payroll run with additional fees per employee. For one employee on bi-weekly payroll (most common) this will amount to $34.00 - $40.00 + HST per month.</p>"
                  }
                }, {
            "@type": "Question",
              "name": "How do I do the bookkeeping for a small company?",
                "acceptedAnswer": {
              "@type": "Answer",
                "text": "<p>The best way to ensure correct bookkeeping for your small company is to hire a high end bookkeeping provider. A provider such as Certus Bookkeeping will ensure that an efficient infrastructure is put in place and they will manage this infrastructure on the back-end. This will not only reduce your internal workload and allow you to focus on growing your business but it will also allow you to effectively scale as you continue to bring in more and more customers.<p>"
             }
           } 
          ]
              }`}
        </script>
      </Helmet>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
          <h2 className="text-center text-3xl font-extrabold text-gray-900 sm:text-4xl">
            Frequently Asked Questions
          </h2>
          <dl className="mt-6 space-y-6 divide-y divide-gray-200">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt className="text-lg">
                      <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                        <span className="font-medium text-gray-900">
                          {faq.question}
                        </span>
                        <span className="ml-6 h-7 flex items-center">
                          <ChevronDownIcon
                            className={classNames(
                              open ? "-rotate-180" : "rotate-0",
                              "h-6 w-6 transform"
                            )}
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base text-gray-500">{faq.answer}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
